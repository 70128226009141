import styled from 'styled-components';

export const Wrapper = styled.div`
  overflow: hidden;

  > [class*='page'] {
    transition: all 500ms ease;
  }

  .page-exit {
    opacity: 1;
    transform: translateY(0);
  }

  .page-exit-active {
    opacity: 0;
    transform: translateY(50px);
  }

  .page-enter {
    opacity: 0;
    transform: translateY(50px);
  }

  .page-enter-active {
    opacity: 1;
    transform: translateY(0);
  }

  .home {
    transform: unset;
  }
`;

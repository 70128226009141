import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - 100px);
`;

export const HeaderContent = styled.div`
  width: 100%;
  padding-top: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 372px;

  /* @media screen and (max-width: 800px) {
    background-image: linear-gradient(#fffc, #fffc), url(${(props) =>
      props.bg});
    background-repeat: no-repeat, no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    padding: 100px ${(props) => props.theme.space.sm} 50px;
    justify-content: center;
  } */

  @media screen and (max-width: 800px) {
    padding: 130px ${(props) => props.theme.space.sm} 16px;
  }

  @media screen and (max-height: 440px),
    (max-width: 400px) and (max-height: 720px) {
    padding: 130px ${(props) => props.theme.space.sm} 16px;
  }
`;

export const PageTitle = styled.h1`
  font-size: 64px;
  font-weight: 700;
  letter-spacing: -1.28px;
  line-height: 64px;
  max-width: 600px;
  text-align: center;

  @media screen and (max-width: 800px) {
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 48px !important;
    line-height: 52px;
  }
  @media screen and (max-width: 650px) {
    font-size: 2.8rem;
    line-height: 2.8rem;
  }
`;

export const PageSubTitle = styled.p`
  font-size: 17px;
  line-height: 26px;
  margin-top: 26px;
  max-width: 650px;
  text-align: center;

  @media screen and (max-width: 800px) {
    margin-top: 16px;
  }
`;

export const PageBannerContainer = styled.div`
  max-height: 550px;
  height: 100%;
  width: 100%;
  margin-top: 60px;

  > span {
    width: 100%;
  }

  @media screen and (max-width: 800px) {
    margin-top: 24px;

    img {
      height: 400px;
    }
  }

  @media screen and (max-width: 440px) {
    margin-top: 40px;

    img {
      height: 200px;
    }
  }
`;

// export const PageBanner = styled(LazyLoadImage).attrs({
//   effect: 'blur',
// })`
//   height: 100%;
//   width: 100%;
//   object-fit: cover;
// `;

export const PageBanner = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  align-items: center;
`;

export const SecondTitleContainer = styled.div`
  width: 100%;
  margin: 90px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: '. title title';
  grid-column-gap: 40px;

  @media screen and (max-width: 800px) {
    grid-template-areas: 'title title title';
    margin: 40px 0 50px;
  }
  @media screen and (max-width: 690px) {
    margin: 20px 0 50px;
  }
`;

export const SecondTitle = styled.h3`
  grid-area: title;
  font-size: 36px;
  letter-spacing: -0.36px;
  line-height: 40px;

  @media screen and (max-width: 690px) {
    font-size: 2rem;
    line-height: 2rem;
  }
  @media screen and (max-width: 450px) {
    margin-top: 32px;
    font-size: 1.8rem;
    line-height: 1.8rem;
    text-align: center;
  }
`;

export const SectionsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 40px;

  @media screen and (max-width: 800px) {
    grid-template-columns: unset;
    grid-template-rows: auto auto auto;
    grid-row-gap: 40px;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionTitle = styled.h4`
  font-size: 18px;
  letter-spacing: 0.72px;
  text-transform: uppercase;
  margin-bottom: 24px;
`;

export const SectionText = styled.p`
  font-size: 17px;
  line-height: 26px;
`;

export const SectionImagesContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 3px;
  margin-top: 60px;
`;

export const SectionImage = styled(LazyLoadImage).attrs({
  effect: 'blur',
})`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const SpotlightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 80px;
`;

export const SpotlightTitle = styled.h2`
  font-size: 64px;
  letter-spacing: -0.64px;
  line-height: 40px;
  text-align: center;

  @media screen and (max-width: 850px) {
    font-size: 3rem;
  }
  @media screen and (max-width: 630px) {
    font-size: 2.4rem;
    max-width: 310px;
  }
`;

export const SpotlightSubtitle = styled.p`
  font-size: 17px;
  line-height: 26px;
  margin-top: 16px;
  max-width: 650px;
  text-align: center;
`;

export const SpotlightContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0;
  margin-top: 50px;

  @media screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 20px;
  }
`;

export const SpotlightImage = styled(LazyLoadImage).attrs({
  effect: 'blur',
})`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const SpotlightTextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 65px;
  background-color: ${(props) => props.theme.color.light2};

  @media screen and (max-width: 1000px) {
    /* background-image: linear-gradient(#fffc, #fffc), url(${(props) =>
      props.bg});
    background-repeat: no-repeat, no-repeat;
    background-size: cover;
    background-position: center;
    max-width: 600px; */

    max-width: 500px;
  }
  @media screen and (max-width: 500px) {
    padding: 30px;
  }
`;

export const SpotlightContentTitle = styled.h4`
  font-size: 18px;
  letter-spacing: 0.72px;
  text-transform: uppercase;
  margin-bottom: 24px;
`;

export const SpotlightContentText = styled.p`
  font-size: 17px;
  line-height: 26px;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 80px 0 90px;
  position: relative;

  &::before {
    content: '';
    width: 48px;
    height: 4px;
    position: absolute;
    top: -4px;
    left: 50%;
    transform: translate(-50%);
    background-color: ${(props) => props.theme.color.blackSolid};
  }
`;

export const ContactTitle = styled.h3`
  font-size: 36px;
  letter-spacing: -0.36px;
  line-height: 40px;
  margin-top: 70px;
  text-align: center;

  @media screen and (max-width: 460px) {
    max-width: 250px;
  }
`;

export const ContactSubtitle = styled.p`
  font-size: 17px;
  line-height: 26px;
  margin: 20px 0 45px;
  max-width: 480px;
  text-align: center;
`;

export const CustomSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 80px;
`;

export const CustomSectionTitle = styled.h2`
  font-size: 54px;
  letter-spacing: -0.54px;
  line-height: 50px;
  text-align: center;
  position: relative;

  &::after {
    content: '';
    width: 64px;
    height: 3px;
    position: absolute;
    bottom: -45px;
    left: 50%;
    transform: translate(-50%);
    background-color: ${(props) => props.theme.color.gray3};
  }

  @media screen and (max-width: 600px) {
    font-size: 2.8rem;
    &::after {
      bottom: -15px;
    }
  }
  @media screen and (max-width: 500px) {
    font-size: 2.4rem;
  }
  @media screen and (max-width: 430px) {
    max-width: 230px;
    line-height: 2.4rem;
  }
`;

export const CustomSectionTextContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 62px;
  margin-top: 80px;

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-row-gap: 15px;
    margin-top: 30px;
  }
`;

export const CustomSectionText = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 17px;
  line-height: 26px;
`;

export const CustomSectionImagesContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 0;
  margin-top: 80px;

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-row-gap: 1.6rem;
    margin-top: 50px;
    padding: 0 1.6rem;
  }
`;

export const CustomSectionImage = styled(LazyLoadImage).attrs({
  effect: 'blur',
})`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

import styled, { css } from 'styled-components';
import { Instagram, FacebookF } from 'styled-icons/fa-brands';

export const Container = styled.footer`
  display: ${(props) => (props.isAtHome ? 'none' : 'flex')};
  justify-content: center;
  width: 100%;
  background-color: ${(props) => props.theme.bgFooter};
  z-index: ${(props) => props.theme.zIndex.footer};

  body.menu-open & {
    margin-right: var(--scrollbar-width);
  }
`;

export const Main = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 700px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export const Copy = styled.div`
  margin: 24px 0;

  @media screen and (max-width: 700px) {
    margin-top: 12px;
  }
`;

export const CopyLine = styled.p`
  font-size: 16px;
  line-height: 26px;
  color: ${(props) => props.theme.color.dark2};

  @media screen and (max-width: 700px) {
    text-align: center;
  }

  @media screen and (max-width: 440px) {
    max-width: 250px;
  }
`;

export const LinkFoco = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  position: relative;
  transition: ${(props) => props.theme.transition.default};

  &::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: ${(props) => props.theme.color.blackSolid};
    transition: ${(props) => props.theme.transition.fast};
  }

  &:hover {
    color: ${(props) => props.theme.color.blackSolid};

    &::after {
      width: 100%;
    }
  }
`;

export const Social = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 700px) {
    margin-top: 24px;
    flex-direction: column;
  }
`;

export const Follow = styled.h4`
  color: ${(props) => props.theme.color.gray};
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.56px;
  text-transform: uppercase;
  margin-right: 10px;

  @media screen and (max-width: 700px) {
    margin-right: 0px;
  }
`;

export const MediaLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})``;

const Icon = css`
  height: 45px;
  color: ${(props) => props.theme.color.blackSolid};
  padding: 10px;
  cursor: pointer;
  transition: ${(props) => props.theme.transition.fast};

  &:hover {
    transform: scale(1.15);
  }
`;

export const IconInstagram = styled(Instagram)`
  ${Icon}
`;

export const IconFacebook = styled(FacebookF)`
  ${Icon}
`;

import React from 'react';

import Content from '~/components/Content';
import ContactForm from '~/components/ContactForm';

import * as S from './styles';

function Contact() {
  return (
    <Content>
      <S.Main>
        <S.HeaderContent>
          <S.PageTitle>Contact</S.PageTitle>
          <S.PageSubTitle>
            Tell us more about your trip and preferences and count on us to plan
            your travel for you!
          </S.PageSubTitle>
        </S.HeaderContent>

        <S.Body>
          <S.AsideInfo>
            <S.AsideSection>
              <S.SectionTitle>Phones:</S.SectionTitle>
              <S.SectionLine>
                <b>+44</b> 7391177292
              </S.SectionLine>
            </S.AsideSection>
            <S.AsideSection>
              <S.SectionTitle>E-mail:</S.SectionTitle>
              <S.SectionLine>renata@sabiaconcierge.com</S.SectionLine>
              <S.SectionLine>contact@sabiaconcierge.com</S.SectionLine>
            </S.AsideSection>
          </S.AsideInfo>
          <S.FormSection>
            <S.FormTitle>Get in touch</S.FormTitle>
            <ContactForm />
          </S.FormSection>
        </S.Body>
      </S.Main>
    </Content>
  );
}

export default Contact;

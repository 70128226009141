import React from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';

import Routes from '~/routes';

import Themes from '~/styles/themes';
import GlobalStyle from '~/styles/global';

import Header from '~/components/Header';
import Footer from '~/components/Footer';

import '~/config/ReactotronConfig';

function App() {
  return (
    <ThemeProvider theme={Themes.light}>
      <Router basename="/">
        <GlobalStyle />
        <Header />
        <Routes />
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;

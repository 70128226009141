import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ChevronCircleRight } from 'styled-icons/fa-solid';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100vh;

  @media screen and (max-width: 1000px) {
    background-image: url(${(props) => props.bg});
    background-repeat: no-repeat, no-repeat;
    background-size: cover;
    background-position: center;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column-reverse;
    background: none;
    justify-content: flex-end;
    padding-top: 115px;
    width: 100vw;
  }
`;

export const TextSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 65px;
  max-width: 700px;
  min-width: 520px;
  width: min-content;
  position: relative;

  @media screen and (max-width: 1000px) {
    background-color: #fffd;
    padding: 0 35px;
  }
  @media screen and (max-width: 800px) {
    max-width: 100vw;
    min-width: unset;
    width: 100%;
    justify-content: flex-start;
    padding: ${(props) => props.theme.space.sm};
  }
`;

export const ScrollIcon = styled.div`
  position: absolute;
  bottom: 40px;
  right: -100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Gilroy', sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${(props) => props.theme.bg};
  transition: ${(props) => props.theme.transition.default};
  opacity: 0;
  z-index: 1;

  svg {
    margin-bottom: 10px;
  }

  ${Container}:hover & {
    opacity: 1;
  }

  @media screen and (max-width: 1000px) {
    right: -80px;
  }
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const Hospitality = styled.h1`
  margin-bottom: 30px;
  padding: 10px 20px;
  color: ${(props) => props.theme.color.pink};
  border: 2px solid ${(props) => props.theme.color.pink};
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.56px;
  text-transform: uppercase;
  width: max-content;
  cursor: default;
  transition: ${(props) => props.theme.transition.default};

  &:hover {
    background-color: ${(props) => props.theme.color.pink};
    color: ${(props) => props.theme.bg};
  }

  @media screen and (max-height: 700px), (max-width: 800px) {
    display: none;
  }
`;

export const Title = styled.span`
  display: block;
  margin-bottom: 17px;
  cursor: default;

  @media screen and (max-height: 450px) {
    display: flex;
    justify-content: space-between;
  }
`;

export const PreTitle = styled.h1`
  font-family: 'Gilroy', sans-serif;
  font-size: 80px;
  font-weight: 700;
  letter-spacing: -0.8px;
  line-height: 78px;
  color: ${(props) => props.theme.color.green};

  @media screen and (max-width: 800px) {
    color: ${(props) => props.theme.color.green}};
  }
  @media screen and (max-width: 550px) {
    font-size: ${(props) => props.theme.space.md};
    line-height: ${(props) => props.theme.space.md};
  }

  @media screen and (max-height: 450px) {
    font-size: ${(props) => props.theme.space.md};
    line-height: ${(props) => props.theme.space.md};
  }
`;

export const CityName = styled(PreTitle)`
  color: ${(props) => props.theme.color.green};
  white-space: nowrap;

  @media screen and (max-width: 800px) {
    white-space: unset;
    color: ${(props) => props.theme.color.green};
  }
`;

export const Text = styled.p`
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 18px;
  cursor: default;

  @media screen and (max-height: 550px), (max-width: 800px) {
    display: none;
  }
`;

export const KnowMore = styled(Link)`
  display: flex;
  align-items: center;
  font-family: 'Gilroy', sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  width: max-content;
  transition: ${(props) => props.theme.transition.default};

  &:hover {
    svg {
      margin: 0 6px;
    }
  }
`;

export const ArrowIcon = styled(ChevronCircleRight)`
  margin-right: 12px;
  width: 14px;
  height: 14px;
  transition: ${(props) => props.theme.transition.default};
`;

export const ImageContainer = styled.div`
  width: 100%;
  min-height: 100vh;

  > span {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 1000px) {
    display: none;
  }

  @media screen and (max-width: 800px) {
    display: flex;
    min-height: unset;
    max-height: 40vh;

    > span {
      width: 100%;
      height: 40vh;
    }
  }
`;

export const Image = styled(LazyLoadImage).attrs({
  effect: 'blur',
})`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

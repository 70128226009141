import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Content from '~/components/Content';

import * as S from './styles';

function Footer() {
  const [isAtHome, setIsAtHome] = useState(true);

  const location = useLocation();

  useEffect(() => {
    setIsAtHome(location.pathname === '/');
  }, [location]);

  return (
    <S.Container isAtHome={isAtHome}>
      <Content>
        <S.Main>
          <S.Copy>
            <S.CopyLine>
              2019. Sabiá Concierge &amp; Hospitality - All rights reserved
            </S.CopyLine>
            <S.CopyLine>
              Developed by{' '}
              <S.LinkFoco href="https://www.fococomunicacao.com/">
                Foco Comunicação
              </S.LinkFoco>
            </S.CopyLine>
          </S.Copy>
          <S.Social>
            <S.Follow>Follow Us:</S.Follow>
            <div>
              <S.MediaLink href="https://instagram.com/sabiaconcierge">
                <S.IconInstagram />
              </S.MediaLink>
              {/* <S.MediaLink href="http://google.com">
                <S.IconFacebook />
              </S.MediaLink> */}
            </div>
          </S.Social>
        </S.Main>
      </Content>
    </S.Container>
  );
}

export default Footer;

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Container from '~/components/Container';
import Content from '~/components/Content';
import ContactForm from '~/components/ContactForm';

import * as S from './styles';

const hyphenToCamel = (stringToParse) => {
  return stringToParse.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
};

function Explore() {
  const [city, setCity] = useState(null);
  const [banner, setBanner] = useState(null);
  const [sectionImages, setSectionImages] = useState([]);
  const [spotlightSectionImage, setSpotlightSectionImage] = useState(null);
  const [customSectionsImages, setCustomSectionsImages] = useState([]);

  const location = useLocation();

  useEffect(() => {
    const importCity = async () => {
      const cityName = hyphenToCamel(
        location.pathname.replace('/explore/', '')
      );

      const { [cityName]: imported } = await import(`content`);
      setCity(imported);

      const { default: bannerImg } = await import(
        `assets/images/content/${cityName}/${imported.explore.bannerImage}`
      );
      setBanner(bannerImg);

      const { default: spotlightSectionImg } = await import(
        `assets/images/content/${cityName}/${imported.explore.spotlightSection.section.image}`
      );
      setSpotlightSectionImage(spotlightSectionImg);
      if (imported.explore.sectionImages) {
        imported.explore.sectionImages.forEach(async (img) => {
          const { default: sectionImg } = await import(
            `assets/images/content/${cityName}/${img}`
          );
          setSectionImages((state) => [...state, sectionImg]);
        });
      }

      if (imported.explore.customSections) {
        const importAllCustomImgs = imported.explore.customSections.map(
          async (section) => {
            const importedCustomImgs = [];
            const importCustomImgs = section.images.map(async (img) => {
              const { default: customSectionImg } = await import(
                `assets/images/content/${cityName}/${img}`
              );
              return importedCustomImgs.push(customSectionImg);
            });

            await Promise.all(importCustomImgs);
            return setCustomSectionsImages((state) => [
              ...state,
              importedCustomImgs,
            ]);
          }
        );

        await Promise.all(importAllCustomImgs);
      }
    };

    importCity();
  }, [location]);

  return (
    <S.Container>
      {city && city.explore && (
        <>
          <Container>
            <S.HeaderContent bg={banner}>
              <div />
              <S.PageTitle>{city.explore.title}</S.PageTitle>
              <S.PageSubTitle>{city.explore.subtitle}</S.PageSubTitle>
            </S.HeaderContent>
            <S.PageBannerContainer>
              <S.PageBanner src={banner} />
            </S.PageBannerContainer>
          </Container>

          <Content>
            <S.Body>
              <S.SecondTitleContainer>
                <S.SecondTitle>{city.explore.secondTitle}</S.SecondTitle>
              </S.SecondTitleContainer>

              <S.SectionsContainer>
                {city.explore.sections.map((section) => (
                  <S.Section key={Math.random()}>
                    <S.SectionTitle>{section.title}</S.SectionTitle>
                    <S.SectionText>{section.text}</S.SectionText>
                  </S.Section>
                ))}
              </S.SectionsContainer>
              {sectionImages.length > 0 && (
                <S.SectionImagesContainer>
                  {sectionImages.map((img) => (
                    <S.SectionImage src={img} key={Math.random()} />
                  ))}
                </S.SectionImagesContainer>
              )}
            </S.Body>
          </Content>

          {city.explore.customSections &&
            city.explore.customSections.map((section, index) => (
              <S.CustomSectionContainer key={section.title}>
                {section.title && (
                  <Content>
                    <S.Body>
                      <S.CustomSectionTitle>
                        {section.title}
                      </S.CustomSectionTitle>
                      <S.CustomSectionTextContainer>
                        <S.CustomSectionText>
                          {section.textColumn1}
                        </S.CustomSectionText>
                        <S.CustomSectionText>
                          {section.textColumn2}
                        </S.CustomSectionText>
                      </S.CustomSectionTextContainer>
                    </S.Body>
                  </Content>
                )}
                <Container>
                  <S.Body>
                    <S.CustomSectionImagesContainer>
                      {!!customSectionsImages.length &&
                        customSectionsImages[index].map((img) => (
                          <S.CustomSectionImage src={img} key={Math.random()} />
                        ))}
                    </S.CustomSectionImagesContainer>
                  </S.Body>
                </Container>
              </S.CustomSectionContainer>
            ))}

          <Content>
            <S.Body>
              <S.SpotlightContainer>
                <S.SpotlightTitle>
                  {city.explore.spotlightSection.title}
                </S.SpotlightTitle>
                <S.SpotlightSubtitle>
                  {city.explore.spotlightSection.subtitle}
                </S.SpotlightSubtitle>
                <S.SpotlightContent>
                  <S.SpotlightImage src={spotlightSectionImage} />
                  <S.SpotlightTextContent bg={spotlightSectionImage}>
                    <S.SpotlightContentTitle>
                      {city.explore.spotlightSection.section.title}
                    </S.SpotlightContentTitle>
                    {city.explore.spotlightSection.section.text.map(
                      (paragraph) => (
                        <S.SpotlightContentText key={Math.random()}>
                          {paragraph}
                        </S.SpotlightContentText>
                      )
                    )}
                  </S.SpotlightTextContent>
                </S.SpotlightContent>
              </S.SpotlightContainer>

              <S.ContactContainer>
                <S.ContactTitle>Interested? Get in touch.</S.ContactTitle>
                <S.ContactSubtitle>
                  Tell us more about your trip and preferences and count on us
                  to plan your travel for you!
                </S.ContactSubtitle>
                <ContactForm center />
              </S.ContactContainer>
            </S.Body>
          </Content>
        </>
      )}
    </S.Container>
  );
}

export default Explore;

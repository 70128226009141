import React, { useState, useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import * as S from './styles';

export default function Input({ name, label, ...rest }) {
  const [active, setActive] = useState(false);

  const inputRef = useRef(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <S.Container>
      <S.InputBox
        label={label.toString() || undefined}
        active={!!label && active ? 'active' : undefined}
      >
        <label htmlFor={fieldName}>{label}</label>
        <input
          id={fieldName}
          name={fieldName}
          ref={inputRef}
          defaultValue={defaultValue}
          onFocus={() => setActive(true)}
          onBlur={() => setActive(!!inputRef.current.value)}
          {...rest}
        />
      </S.InputBox>
      {error && <span className="error">{error}</span>}
    </S.Container>
  );
}

export const space = {
  xxs: `.4rem`,
  xs: `.8rem`,
  sm: `1.6rem`,
  default: `2.4rem`,
  md: `3.2rem`,
  lg: `4.8rem`,
  xlg: `6.4rem`,
  xxlg: `9.6rem`,
};

export const color = {
  blackSolid: '#000000',
  darker: '#242020',
  dark: '#2e2929',
  dark2: '#2e2e2e',
  gray: '#7c7c7c',
  gray2: '#8e8e8e',
  gray3: '#afafaf',
  light: '#f0f0f0',
  light2: '#f2f2f2',
  whiteSolid: '#FFFFFF',
  redSolid: '#FF0000',
  pink: '#D88A9B',
  green: '#14534A',
};

export const zIndex = {
  bg: `-1`,
  default: `1`,
  footer: `5`,
  header: `5`,
  homeNav: `4`,
  menu: `10`,
  menuButton: `20`,
};

export const transition = {
  fast: `250ms ease`,
  default: `400ms ease`,
  menu: `700ms ease-in`,
};

export const maxSize = {
  lg: `1600px`,
  md: `1000px`,
};

import styled, { css } from 'styled-components';

export const Background = styled.div`
  position: relative;
  display: flex;
  z-index: ${(props) => props.theme.zIndex.menu - 1};
  pointer-events: visible;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${(props) =>
      props.open ? `calc(var(--screen-diagonal) * 2)` : '40px'};
    height: ${(props) =>
      props.open ? `calc(var(--screen-diagonal) * 2)` : '40px'};
    border-radius: 50%;
    background-color: ${(props) =>
      props.open ? props.theme.color.darker : props.theme.color.blackSolid};
    transition: ${(props) => props.theme.transition.menu};
  }
`;

export const Content = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  border-radius: 50%;
  z-index: ${(props) => props.theme.zIndex.menuButton};

  > div {
    width: 15px;
    height: 2px;
    border-radius: 4px;
    background-color: ${(props) => props.theme.color.whiteSolid};
    transition: ${(props) => props.theme.transition.default};

    &:not(:last-child) {
      margin-bottom: 4px;
    }

    ${({ open }) =>
      open &&
      css`
        opacity: 0;
        &:first-child {
          opacity: 1;
          width: 26px;
          height: 3px;
          transform: translate(5px, 6.5px) rotate(-45deg);
        }
        &:last-child {
          opacity: 1;
          width: 26px;
          height: 3px;
          transform: translate(5px, -6.5px) rotate(45deg);
        }
      `}
  }
`;

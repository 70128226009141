import React, { forwardRef } from 'react';
import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom';
import { Swiper, Mousewheel, Controller, HashNavigation } from 'swiper';

import * as S from './styles';

const HomeNav = forwardRef(({ cities, activeSlide, mainSwiperRef }, ref) => {
  const navSwiperParams = {
    Swiper,
    modules: [Mousewheel, Controller, HashNavigation],

    direction: 'vertical',
    mousewheel: true,
    slidesPerView: 'auto',
    hashNavigation: true,
    centeredSlides: true,
    freeMode: true,
  };

  const handleClick = (index) => {
    mainSwiperRef.current.swiper.slideTo(index);
  };

  return (
    <S.Container>
      <S.Nav>
        <ReactIdSwiperCustom {...navSwiperParams} ref={ref}>
          {cities.map((city, index) => (
            <S.Slide key={city.slug} data-hash={city.slug}>
              <S.City
                active={index === activeSlide}
                onClick={() => handleClick(index)}
              >
                {city.title}
              </S.City>
            </S.Slide>
          ))}
        </ReactIdSwiperCustom>
      </S.Nav>
    </S.Container>
  );
});

export default HomeNav;

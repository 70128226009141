import styled, { css } from 'styled-components';
import { ChevronUp } from 'styled-icons/ionicons-solid';

export const Container = styled.button.attrs({
  type: 'button',
})`
  width: 39px;
  height: 39px;
  background-color: ${(props) => props.theme.color.dark};
  position: fixed;
  bottom: ${(props) => props.theme.space.sm};
  right: ${(props) => props.theme.space.sm};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: visible;
  opacity: 0.9;
  transition: ${(props) => props.theme.transition.default}, margin 0s;

  body.menu-open & {
    margin-right: var(--scrollbar-width);
  }

  &:hover {
    opacity: 1;
  }

  ${(props) =>
    !props.showScroll &&
    css`
      visibility: hidden;
      opacity: 0;
      transform: translate(0, -30px);

      &:hover {
        opacity: 0;
      }
    `}
`;

export const IconArrow = styled(ChevronUp)`
  width: 20px;
  color: ${(props) => props.theme.bg};
`;

import React, { useState, useEffect, useRef } from 'react';
import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom';
import { Swiper, Mousewheel, Controller, HashNavigation } from 'swiper';

import Container from '~/components/Container';
import HomeNav from '~/components/HomeNav';
import CityFront from '~/components/CityFront';

import * as allCities from '~/content';

import * as S from './styles';

function Home() {
  const [cities] = useState(Object.values(allCities));
  const [activeSlide, setActiveSlide] = useState(0);

  const mainSwiperRef = useRef(null);
  const navSwiperRef = useRef(null);

  const handleSlideChange = () => {
    setActiveSlide(mainSwiperRef.current.swiper.activeIndex);
  };

  const mainSwiperParams = {
    Swiper,
    modules: [Mousewheel, Controller, HashNavigation],

    direction: window.innerWidth <= 800 ? 'horizontal' : 'vertical',
    mousewheel: true,
    slidesPerView: 1,
    hashNavigation: true,
    on: {
      slideChange: handleSlideChange,
    },
  };

  useEffect(() => {
    const mainSwiper = mainSwiperRef.current.swiper;
    const menuSwiper = navSwiperRef.current.swiper;

    if (mainSwiper.controller && menuSwiper.controller) {
      mainSwiper.controller.control = menuSwiper;
    }
  }, []);

  return (
    <Container>
      <S.Main>
        <HomeNav
          ref={navSwiperRef}
          mainSwiperRef={mainSwiperRef}
          cities={cities}
          activeSlide={activeSlide}
        />
        <S.Cities>
          <ReactIdSwiperCustom {...mainSwiperParams} ref={mainSwiperRef}>
            {cities.map((city) => (
              <S.Slide key={city.slug} data-hash={city.slug}>
                <CityFront city={city} />
              </S.Slide>
            ))}
          </ReactIdSwiperCustom>
        </S.Cities>
      </S.Main>
    </Container>
  );
}

export default Home;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;

  &:not(:last-child) {
    margin-right: 20px;
  }

  @media screen and (max-width: 610px) {
    margin-right: 0;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  textarea {
    resize: none;
    display: flex;
    flex: 1;
    min-height: 170px;
    font-size: 17px;
    font-family: 'Barlow', sans-serif;
    color: ${(props) => props.theme.color.dark};
    padding: 20px;
    border: 1px solid ${(props) => props.theme.color.gray3};
  }

  span {
    font-size: 14px;
    color: ${(props) => props.theme.danger};
    margin: 4px 0;
  }
`;

import styled from 'styled-components';

export const Main = styled.main`
  display: flex;
  justify-content: center;
  min-height: 100vh;
`;

export const Cities = styled.div`
  width: 100%;

  .swiper-container {
    height: 100vh;
    overflow: hidden;
  }

  .swiper-wrapper {
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 800px) {
    .swiper-wrapper {
      flex-direction: row;
    }
  }
`;

export const Slide = styled.div``;

import React, { useRef, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Input, InputMask, Textarea } from '~/components/Forms';
import * as S from './styles';
import api from '../../services/api';

function ContactForm({ center }) {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const formRef = useRef();

  async function handleSubmit(data) {
    try {
      formRef.current.setErrors({});
      setErrorMessage('');
      setSuccessMessage('');
      setLoading(false);

      const schema = Yup.object().shape({
        name: Yup.string().required('Field required'),
        email: Yup.string().email('Invalid e-mail').required('Field required'),
        country: Yup.string().required('Field required'),
        phone: Yup.string()
          .matches(/[+] [0-9]{10,14}/, 'Invalid phone number')
          .required('Field required'),
        message: Yup.string().required('Field required'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setLoading(true);
      const response = await api.post('sendmail', data, {
        headers: {
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      });
      setLoading(false);
      setSuccessMessage(response.data.message);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      } else {
        setErrorMessage('Could not send the message. Please try again later.');
      }
    }
  }

  return (
    <S.Container center={center}>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <S.Line>
          <Input name="name" label="Your Name:" />
        </S.Line>
        <S.Line>
          <Input name="email" label="E-mail:" />
        </S.Line>
        <S.Line>
          <Input name="country" label="Country:" />
          <InputMask
            name="phone"
            label="Phone:"
            mask="+ 99999999999999"
            // mask={[/\+ /, /^(?:[0-9] ?){6,14}[0-9]$/]}
            maskPlaceholder=""
          />
        </S.Line>
        <S.Line>
          <Textarea
            name="message"
            placeholder="Tell us more about you and your trip..."
          />
        </S.Line>
        <S.SendButton disabled={loading}>
          {loading ? <S.Loading /> : 'Send message'}
        </S.SendButton>

        {!!successMessage && (
          <S.Success center={center}>{successMessage}</S.Success>
        )}
        {!!errorMessage && <S.Error center={center}>{errorMessage}</S.Error>}
      </Form>
    </S.Container>
  );
}

export default ContactForm;

import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

import { Wrapper } from './transition';

import Intro from '~/pages/Intro';
import Home from '~/pages/Home';
import About from '~/pages/About';
import Consultancy from '~/pages/Consultancy';
import Contact from '~/pages/Contact';
import Explore from '~/pages/Explore';

export default function Routes() {
  const location = useLocation();

  return (
    <Wrapper>
      <SwitchTransition component={null}>
        <CSSTransition
          key={`${location.pathname}`}
          timeout={500}
          classNames="page"
        >
          <Switch location={location}>
            <Route path="/" exact>
              <div className="intro">
                <Intro />
              </div>
            </Route>
            <Route path="/home" exact>
              <div className="home">
                <Home />
              </div>
            </Route>
            <Route path="/explore/:city" exact component={Explore} />
            <Route path="/about" exact component={About} />
            <Route path="/consultancy" exact component={Consultancy} />
            <Route path="/contact" exact component={Contact} />
          </Switch>
        </CSSTransition>
      </SwitchTransition>
    </Wrapper>
  );
}

import { css } from 'styled-components';

import Black from '~/assets/fonts/Gilroy-Black.otf';
import Bold from '~/assets/fonts/Gilroy-Bold.otf';
import ExtraBold from '~/assets/fonts/Gilroy-ExtraBold.otf';
import Light from '~/assets/fonts/Gilroy-Light.otf';
import Medium from '~/assets/fonts/Gilroy-Medium.otf';
import Regular from '~/assets/fonts/Gilroy-Regular.otf';
import SemiBold from '~/assets/fonts/Gilroy-SemiBold.otf';
import Thin from '~/assets/fonts/Gilroy-Thin.otf';

const Girloy = css`
  @font-face {
    font-family: 'Gilroy';
    src: url(${Black}) format('opentype');
    font-weight: 900;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gilroy';
    src: url(${Bold}) format('opentype');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gilroy';
    src: url(${ExtraBold}) format('opentype');
    font-weight: 800;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gilroy';
    src: url(${Light}) format('opentype');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gilroy';
    src: url(${Medium}) format('opentype');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gilroy';
    src: url(${Regular}) format('opentype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gilroy';
    src: url(${SemiBold}) format('opentype');
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gilroy';
    src: url(${Thin}) format('opentype');
    font-weight: 100;
    font-style: normal;
  }
`;

export default Girloy;

import styled, { css } from 'styled-components';
import { Instagram, FacebookF } from 'styled-icons/fa-brands';

export const Container = styled.div`
  z-index: ${(props) => props.theme.zIndex.menu};
`;

export const Content = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  visibility: hidden;
  z-index: ${(props) => props.theme.zIndex.menu};
  transition: ${(props) => `${props.theme.transition.menu}`};

  ${(props) =>
    props.open &&
    css`
      visibility: visible;
      transition: ${props.theme.transition.default};
    `}
`;

export const Items = styled.nav`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: visible;

  @media screen and (max-height: 650px) {
    top: 20%;
    transform: translate(-50%, 0);
  }
  @media screen and (max-height: 450px) {
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const Item = styled.h1`
  color: ${(props) => props.theme.color.whiteSolid};
  font-family: 'Gilroy', sans-serif;
  font-size: 62px;
  font-weight: 700;
  letter-spacing: -0.62px;
  padding: 5px;
  cursor: pointer;
  transition: ${(props) => `${props.theme.transition.default}`};

  opacity: 0;
  transform: translateY(-100px);

  ${(props) =>
    props.open &&
    css`
      opacity: 1;
      transform: translateY(0);
      transition: transform
          ${`${props.theme.transition.default} ${props.delay}ms`},
        opacity ${`${props.theme.transition.default} ${props.delay}ms`},
        color ${props.theme.transition.fast};

      &:hover {
        color: ${props.theme.color.gray};
      }
    `}

  @media screen and (max-height: 450px) {
    font-size: ${(props) => props.theme.space.default};
  }

  @media screen and (max-width: 450px) {
    font-size: ${(props) => props.theme.space.default};
  }

  @media screen and (max-height: 580px) {
    font-size: 48px;
  }
`;

export const Social = styled.div`
  position: fixed;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: visible;
  opacity: 0;
  transition: ${(props) => props.theme.transition.default};

  ${(props) =>
    props.open &&
    css`
      opacity: 1;
      transition: ${props.theme.transition.default} 1400ms;
    `}

  @media screen and (max-height: 690px) {
    transform: translate(-50%, 0);
  }
  @media screen and (max-height: 580px) {
    bottom: 32px;
  }
`;

export const Title = styled.h4`
  color: ${(props) => props.theme.color.whiteSolid};
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.56px;
  text-transform: uppercase;
`;

export const Medias = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
`;

const Icon = css`
  height: 45px;
  color: ${(props) => props.theme.color.whiteSolid};
  padding: 10px;
  cursor: pointer;
  transition: ${(props) => props.theme.transition.fast};

  &:hover {
    transform: scale(1.3);
  }
`;

export const MediaLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})``;

export const IconInstagram = styled(Instagram)`
  ${Icon}
`;

export const IconFacebook = styled(FacebookF)`
  ${Icon}
`;

import styled, { css } from 'styled-components';
import { ArrowNarrowLeft } from 'styled-icons/heroicons-solid';
import { ArrowsExpand } from 'styled-icons/foundation';

export const Container = styled.header`
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: ${(props) => props.theme.transition.default}, padding 0s;
  pointer-events: none;

  body.menu-open & {
    margin-right: var(--scrollbar-width);
  }

  &::before {
    content: '';
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 340px;
    background-color: ${(props) => props.theme.bgHeader};
    z-index: ${(props) => props.theme.zIndex.bg};
    transition: ${(props) => props.theme.transition.default};

    ${(props) =>
      props.path === '/about' &&
      css`
        height: 300px;
        opacity: 1;

        @media screen and (max-width: 500px) {
          height: 250px;
        }
      `}

    ${(props) =>
      props.path === '/consultancy' &&
      css`
        height: 300px;
        opacity: 1;

        @media screen and (max-width: 500px) {
          height: 250px;
        }
      `}

    ${(props) =>
      props.path === '/contact' &&
      css`
        height: 372px;
        opacity: 1;

        @media screen and (max-width: 500px) {
          height: 340px;
        }
        @media screen and (max-width: 372px) {
          height: 372px;
        }
      `}
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: ${(props) =>
    props.path === '/home' ? props.theme.maxSize.lg : '100%'};
  width: 100%;
  padding: 40px;
  z-index: ${(props) => props.theme.zIndex.header};
  transition: ${(props) => props.theme.transition.default};
  position: relative;
  pointer-events: none;

  @media screen and (max-width: 500px) {
    padding: 40px ${(props) => props.theme.space.sm};
  }
`;

export const GoBack = styled.button.attrs((props) => ({
  type: 'button',
  disabled: props.path === '/home' || window.innerWidth <= 500,
}))`
  display: flex;
  margin-top: 10px;
  pointer-events: visible;
  transition: all 500ms ease 500ms;

  &:disabled {
    transition: all 500ms ease;
    opacity: 0;
    cursor: default;
  }

  &:hover {
    svg {
      transform: translate(-10px);
    }
  }
`;

export const GoBackIcon = styled(ArrowNarrowLeft)`
  height: 20px;
  margin-right: 10px;
  transition: ${(props) => props.theme.transition.default};
`;

export const GoBackText = styled.p`
  font-family: 'Gilroy', sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.64px;
  text-transform: uppercase;
`;

export const Logo = styled.div`
  position: absolute;
  opacity: ${(props) => (props.path === '/home' ? 0 : 1)};
  transition: all 500ms ease 500ms;
  left: 50%;
  transform: ${(props) => {
    if (props.path === '/about') return 'translate(-50%) scale(1.3)';
    return 'translate(-50%)';
  }};

  @media screen and (max-width: 500px) {
    opacity: 1;
    left: ${(props) => props.theme.space.sm};
    transform: translateY(-20px) scale(0.8);
    top: 20px;
  }
`;

export const LogoHome = styled.div`
  position: absolute;
  opacity: ${(props) => (props.path === '/home' ? 1 : 0)};
  transition: ${(props) =>
    props.path === '/' ? 'all 500ms ease 1000ms' : 'all 500ms ease'};
  left: 40px;

  @media screen and (max-width: 800px) {
    left: ${(props) => props.theme.space.sm};
    transform: translateY(-20px) scale(0.8);
    top: 20px;
  }

  @media screen and (max-width: 500px) {
    display: none;
  }
`;

export const SwipeIcon = styled.div`
  position: fixed;
  left: 24px;
  bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Gilroy', sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.dark};
  transition: ${(props) => props.theme.transition.default} 1500ms;
  opacity: 0;
  z-index: 1;

  @media screen and (max-width: 800px) {
    opacity: ${(props) => (props.path === '/home' ? '1' : '0')};
  }
`;

export const SlideIcon = styled(ArrowsExpand)`
  width: 22px;
  margin-bottom: 2px;
  transform: rotate(45deg);
  color: ${(props) => props.theme.color.dark};
`;

import React from 'react';

import Content from '~/components/Content';

import * as S from './styles';

import consultancyPic from '~/assets/images/consultancy.png';

function Consultancy() {
  return (
    <Content>
      <S.Main>
        <S.HeaderContent>
          <S.PageTitle>Consultancy</S.PageTitle>
        </S.HeaderContent>
        <S.Body>
          <S.Picture src={consultancyPic} alt="Sabiá Concierge" />
          <S.Article>
            <S.Title>What we do</S.Title>
            <S.Paragraph>
              We are a luxury concierge that tailors our services to the
              individual needs of each client. We are pragmatic, efficient and
              team-players.
            </S.Paragraph>
            <S.Title>Collaborative and support</S.Title>
            <S.Paragraph>
              In order to achieve the best results, we will work closely with
              the clients so that we have a clear path to undertake together. We
              will always look for things to improve for you during your stay so
              that you have an incredible time. Sabiá also maintains close
              contact with agents in order for us to always be able to better
              your experiences.
            </S.Paragraph>
            <S.Title>Unbiased, offering opinions when it counts</S.Title>
            <S.Paragraph>
              We ensure strategies and solutions are bespoke to each specific
              context. We listen closely to the needs of our clients and offer
              suggestions based on our expertise and experience.
            </S.Paragraph>
            <S.Title>How can we help you? </S.Title>
            <S.Paragraph>
              We want to support your business, holidays and special occasions
              by organising everything from the cuisine you will be having,
              guest lists, hospitality, live music and DJs, all the way to the
              after party.
            </S.Paragraph>
          </S.Article>
        </S.Body>
      </S.Main>
    </Content>
  );
}

export default Consultancy;

import styled from 'styled-components';
import { Loader } from '@styled-icons/boxicons-regular';

export const Main = styled.main`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;

  .icon-spin {
    animation: iconSpin 2s infinite linear;
  }

  @keyframes iconSpin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
`;

export const Video = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;

  video {
    position: absolute;
    object-fit: cover;
    width: 100%;
  }
`;

export const Loading = styled(Loader).attrs({
  size: 48,
  className: 'icon-spin',
})`
  color: #242020;
  position: absolute;
`;

export const Button = styled.button.attrs({ type: 'button' })`
  position: absolute;
  padding: 16px 24px;
  border-radius: 32px;
  color: white;
  background-color: black;
  z-index: 99;
  font-size: 16px;
  opacity: 0;
`;

import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 160px;
  height: 100vh;
  padding: 40px;
  padding-right: 0;
  z-index: ${(props) => props.theme.zIndex.homeNav};

  @media screen and (max-width: 1000px) {
    display: none;
    position: absolute;
    right: 20px;
  }
`;

export const Nav = styled.nav`
  display: flex;
  width: 100%;
  flex-direction: column;
  max-height: 60%;
  position: relative;

  .swiper-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .swiper-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    z-index: ${(props) => props.theme.zIndex.homeNav + 1};
    background: linear-gradient(${(props) => props.theme.bg}, transparent);
    pointer-events: none;
  }

  &::after {
    top: unset;
    bottom: 0;
    background: linear-gradient(transparent, ${(props) => props.theme.bg});
  }

  @media screen and (max-width: 1000px) {
    &::before,
    &::after {
      background: none;
    }
  }
`;

export const Slide = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1000px) {
    align-items: flex-end;
  }
`;

export const City = styled.p`
  padding: 8px 0;
  font-family: 'Gilroy', sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.theme.notActive};
  cursor: pointer;
  position: relative;
  transition: ${(props) => props.theme.transition.default};
  width: max-content;

  &:hover {
    transform: scale(1.2) translate(7%);
    width: max-content;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: ${(props) => props.theme.text};
    height: 3px;
    width: 0;
    border-radius: 3px;
    transition: ${(props) => props.theme.transition.default};
  }

  ${(props) =>
    props.active &&
    css`
      font-size: 21px;
      color: ${props.theme.text};
      padding: 24px 0 32px;
      cursor: default;

      &::after {
        bottom: 26px;
        width: 100%;
      }

      &:hover {
        transform: none;
      }
    `}

  @media screen and (max-width: 1000px) {
    &::after {
      left: unset;
      right: 0;
    }
  }
`;

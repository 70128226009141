import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import MenuButton from './Button';

import * as S from './styles';

function Menu() {
  const [open, setOpen] = useState(false);

  const history = useHistory();

  const handleNavigate = (path) => {
    setOpen(false);
    history.push(path);
  };

  useEffect(() => {
    if (open) {
      document.body.classList.add('menu-open');
    } else {
      setTimeout(() => {
        document.body.classList.remove('menu-open');
      }, 800);
    }
  }, [open]);

  return (
    <S.Container>
      <MenuButton open={open} setOpen={setOpen} />
      <S.Content open={open}>
        <S.Items>
          <S.Item
            open={open}
            delay={500}
            onClick={() => handleNavigate('/home')}
          >
            Destinations
          </S.Item>
          <S.Item
            open={open}
            delay={700}
            onClick={() => handleNavigate('/about')}
          >
            About Us
          </S.Item>
          <S.Item
            open={open}
            delay={700}
            onClick={() => handleNavigate('/consultancy')}
          >
            Consultancy
          </S.Item>
          <S.Item
            open={open}
            delay={900}
            onClick={() => handleNavigate('/contact')}
          >
            Contact
          </S.Item>
        </S.Items>
        <S.Social open={open}>
          <S.Title>Follow Us</S.Title>
          <S.Medias>
            <S.MediaLink href="https://instagram.com/sabiaconcierge">
              <S.IconInstagram />
            </S.MediaLink>
            {/* <S.MediaLink href="https://facebook.com/sabiaconcierge">
              <S.IconFacebook />
            </S.MediaLink> */}
          </S.Medias>
        </S.Social>
      </S.Content>
    </S.Container>
  );
}

export default Menu;

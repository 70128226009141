import styled from 'styled-components';

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - 100px);
`;

export const HeaderContent = styled.div`
  width: 100%;
  padding-top: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 372px;

  @media screen and (max-width: 500px) {
    justify-content: flex-end;
    min-height: 300px;
  }
`;

export const PageTitle = styled.h1`
  font-size: 64px;
  font-weight: 700;
  letter-spacing: -1.28px;
  text-align: center;

  @media screen and (max-width: 500px) {
    font-size: ${(props) => props.theme.space.md};
  }
`;

export const PageSubTitle = styled.p`
  font-size: 17px;
  line-height: 26px;
  margin-top: 26px;
  max-width: 480px;
  text-align: center;
`;

export const Body = styled.main`
  display: flex;
  width: 100%;
  padding: 65px 0 85px;
  overflow: hidden;

  @media screen and (max-width: 900px) {
    flex-direction: column-reverse;
    padding-bottom: 20px;
  }
`;

export const AsideInfo = styled.div`
  max-width: max-content;
  display: flex;
  flex-direction: column;
  margin-right: 100px;

  @media screen and (max-width: 900px) {
    margin-right: 0;
    margin-top: 60px;
  }
`;

export const AsideSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

export const SectionTitle = styled.p`
  font-size: 17px;
  line-height: 26px;
  font-weight: 700;
`;

export const SectionLine = styled.p`
  font-size: 17px;
  line-height: 26px;

  b {
    color: ${(props) => props.theme.color.blackSolid};
    font-weight: 500;
  }
`;

export const FormSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  @media screen and (max-width: 900px) {
    align-items: center;
  }
`;

export const FormTitle = styled.h4`
  color: ${(props) => props.theme.color.dark};
  font-size: 18px;
  letter-spacing: 0.72px;
  text-transform: uppercase;
  margin-bottom: 50px;

  @media screen and (max-width: 500px) {
    margin-bottom: 40px;
  }
`;

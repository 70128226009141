export { default as london } from './data/london.json';
export { default as paris } from './data/paris.json';
export { default as milan } from './data/milan.json';
export { default as rome } from './data/rome.json';
export { default as lisbon } from './data/lisbon.json';
export { default as ibiza } from './data/ibiza.json';
export { default as sardinia } from './data/sardinia.json';
export { default as mykonos } from './data/mykonos.json';
export { default as newYork } from './data/new-york.json';
export { default as miami } from './data/miami.json';
export { default as mexicoCity } from './data/mexico-city.json';
export { default as saoPaulo } from './data/sao-paulo.json';
export { default as rio } from './data/rio.json';

import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;

  &:not(:last-child) {
    margin-right: 20px;
  }

  @media screen and (max-width: 610px) {
    margin-right: 0;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  span {
    font-size: 14px;
    color: ${(props) => props.theme.danger};
    margin: 4px 0;
  }
`;

export const InputBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  border: 1px solid ${(props) => props.theme.color.gray3};
  position: relative;

  label {
    display: ${(props) => (props.label ? 'flex' : 'none')};
    font-family: 'Gilroy', sans-serif;
    font-size: 16px;
    font-weight: 700;
    background-color: ${(props) => props.theme.bg};
    color: ${(props) => props.theme.color.blackSolid};

    white-space: nowrap;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate(0, -50%);
    transition: ${(props) => props.theme.transition.fast};
    pointer-events: none;

    &::before {
      content: '';
      position: absolute;
      top: -12px;
      left: 50%;
      transform: translate(-50%);
      width: 0%;
      height: 0;
      z-index: -1;

      border-left: 30px solid transparent;
      border-right: 30px solid transparent;
      border-bottom: 20px solid ${(props) => props.theme.bg};
    }

    ${(props) =>
      props.active &&
      css`
        top: 0;
        font-size: 12px;
        padding: 0 10px;
        left: 10px;
      `};
  }

  input {
    height: 100%;
    font-size: 17px;
    font-family: 'Barlow', sans-serif;
    color: ${(props) => props.theme.color.dark};
    padding: 0 20px;
    border: none;
    width: 100%;
  }
`;

import styled from 'styled-components';
import { Spinner3 } from 'styled-icons/icomoon';

export const Container = styled.div`
  display: flex;
  max-width: ${(props) => (props.center ? '620px' : 'unset')};
  width: 100%;

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: ${(props) => (props.center ? 'center' : 'unset')};
  }
`;

export const Line = styled.div`
  display: flex;
  margin-bottom: 20px;
  width: 100%;

  @media screen and (max-width: 610px) {
    flex-direction: column;
  }
`;

export const SendButton = styled.button.attrs({
  type: 'submit',
})`
  margin-top: 50px;
  padding: 0 20px;
  min-width: 262px;
  min-height: 63px;
  width: min-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.color.blackSolid};
  color: ${(props) => props.theme.color.whiteSolid};
  font-size: 18px;
  transition: ${(props) => props.theme.transition.fast};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

  &:hover {
    opacity: ${(props) => (props.disabled ? '1' : '0.8')};
  }

  @media screen and (max-width: 610px) {
    max-width: 100%;
    width: 100%;
    margin-top: 10px;
  }
`;

export const Success = styled.p`
  font-size: 20px;
  margin-top: 40px;
  text-align: ${(props) => (props.center ? 'center' : 'unset')};
`;

export const Error = styled.p`
  font-size: 20px;
  margin-top: 40px;
  text-align: ${(props) => (props.center ? 'center' : 'unset')};
  color: ${(props) => props.theme.danger};
`;

export const Loading = styled(Spinner3)`
  @keyframes rotateSpinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  height: 25px;
  animation: rotateSpinner 0.75s infinite steps(12, jump-start);
`;

import styled from 'styled-components';

export const Content = styled.div`
  /* display: flex; */
  flex: 1;
  margin-left: auto;
  margin-right: auto;
  max-width: ${(props) => props.theme.maxSize.md};
  width: 100%;
  padding-left: ${(props) => props.theme.space.sm};
  padding-right: ${(props) => props.theme.space.sm};
`;

import React, { useState, useEffect } from 'react';

import { ReactComponent as MouseIcon } from '~/assets/images/mouse-icon.svg';

import * as S from './styles';

const hyphenToCamel = (stringToParse) => {
  return stringToParse.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
};

function CityFront({ city }) {
  const [image, setImage] = useState();

  useEffect(() => {
    const importImage = async () => {
      const { default: img } = await import(
        `assets/images/content/${hyphenToCamel(city.slug)}/${city.home.image}`
      );
      setImage(img);
    };

    if (city.home.image) importImage();
  }, [city]);

  return (
    <S.Container bg={image}>
      <S.TextSection>
        <S.Hospitality>CONCIERGE &amp; HOSPITALITY</S.Hospitality>
        <S.Title>
          <S.PreTitle>{city.home.title.replace(city.title, '')}</S.PreTitle>
          <S.CityName>{city.title}</S.CityName>
        </S.Title>
        <S.Text>{city.home.text}</S.Text>
        {!!city.explore && (
          <S.KnowMore to={`explore/${city.slug}`}>
            <S.ArrowIcon />
            Know more
          </S.KnowMore>
        )}
        <S.ScrollIcon>
          <MouseIcon />
          SCROLL
        </S.ScrollIcon>
      </S.TextSection>
      <S.ImageContainer>
        <S.Image src={image} alt="" />
      </S.ImageContainer>
    </S.Container>
  );
}

export default CityFront;

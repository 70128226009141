import React from 'react';

import Content from '~/components/Content';

import * as S from './styles';

import aboutPic from '~/assets/images/about.png';

import sabiaPic from '~/assets/images/sabia-ilustracao.png';

function About() {
  return (
    <Content>
      <S.Main>
        <S.HeaderContent>
          <S.PageTitle>About Us</S.PageTitle>
        </S.HeaderContent>

        <S.Body>
          <S.Picture src={aboutPic} alt="Sabiá Concierge &amp; Hospitality" />
          <S.Article>
            <S.Title>Founder</S.Title>
            <S.Paragraph>
              Renata with over 15 years in the hospitality industry has been
              working with Cipriani Group over 7 years, most recently managing
              Socialista Lounge, their exclusive private members’ club in
              Mayfair, London. As a result of success the area founded Sabiá in
              order to make better and more tailored made experience for her
              clients.
            </S.Paragraph>
            <S.Paragraph>
              Born and raised in sun-kissed Rio de Janeiro, Renata began her
              professional life as an international model, working with leading
              couture designers in Milan, Miami, Mexico, Lisbon, Germany and
              London.
            </S.Paragraph>
            <S.Paragraph>
              With many years of experience gained by traveling around the
              world, Renata, who is multi-lingual, speaking English, Italian,
              Portuguese and Spanish, moved into the international hospitality
              industry, working for the past ten years with leading groups in
              the sector in cities such as Miami, Mexico City, Milan and London.
            </S.Paragraph>

            <S.Title>Behind Sabiá</S.Title>
            <S.SabiaPicture
              src={sabiaPic}
              alt="Sabiá Concierge &amp; Hospitality"
            />
            <S.Paragraph>
              Sabia has flown around the world, it has flown incessantly,
              learning wonders along the way and on its way to learn evermore.
            </S.Paragraph>
            <S.Paragraph>
              I am a free spirit, flying short or long distances. Experiencing
              and adapting to any place I want to land on to appreciate freedom
              just like a bird, an enchanted Sabiá. A bird that lives freely,
              chirping from tree to tree, always searching for the best spot
              from where to get the best view of the world.
            </S.Paragraph>
            <S.Paragraph>
              My personality is just like the bird Sabiá. I’ve spent many years
              of my life travelling, experiencing the world and living in some
              of the most interesting places on earth.
            </S.Paragraph>
            <S.Paragraph>
              WE ARE SABIÁ - A boutique concierge agency that offers a bespoke
              service, taking you to selected experiences and offering new
              flavours of the world and the very best it can offer.
            </S.Paragraph>
            <S.Paragraph>
              &quot;Sabiá&quot; is the National Bird of Brazil. Like Sabiá, I
              have flown incessantly around the world, on a mission to
              experience and learn more about the paradise in which we live.
            </S.Paragraph>
          </S.Article>
        </S.Body>
      </S.Main>
    </Content>
  );
}

export default About;

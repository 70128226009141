import React from 'react';

import * as S from './styles';

function Button({ open, setOpen }) {
  return (
    <S.Background open={open}>
      <S.Content open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </S.Content>
    </S.Background>
  );
}

export default Button;

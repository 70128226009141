import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';

import { Gilroy } from './fonts';

export default function GlobalStyles() {
  const [scrollbarWidth, setScrollbarWidth] = useState(0);
  const [screenDiagonal, setScreenDiagonal] = useState(0);

  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      setScrollbarWidth(
        window.innerWidth - document.documentElement.clientWidth
      );
    }, 1000);

    setScreenDiagonal(Math.hypot(window.innerWidth, window.innerHeight));
  }, [location]);

  return (
    <Styles scrollbarWidth={scrollbarWidth} screenDiagonal={screenDiagonal} />
  );
}

const Styles = createGlobalStyle`
  ${Gilroy}

  * {
    box-sizing: border-box;
    margin: 0;
    outline: 0;
    padding: 0;
    -webkit-tap-highlight-color: transparent;
  }

  *:focus {
    outline: 0;
  }

  html {
    scroll-behavior: smooth;
  }

  html,
  body {
    min-height: 100vh;
    max-width: 100vw;

    --scrollbar-width: ${(props) => `${props.scrollbarWidth}px`};
    --screen-diagonal: ${(props) => `${props.screenDiagonal}px`};
  }

  body {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    background: ${(props) => props.theme.bg};
    font-family: "Barlow", sans-serif;
    color: ${(props) => props.theme.text};

    &.menu-open {
      overflow: hidden;
      margin-right: var(--scrollbar-width);

      #root {
        overflow: hidden;
        position: relative;

      }
    }
  }

  a, p, input {
    font: 400 15px "Barlow", sans-serif;
    text-decoration: none;
    color: ${(props) => props.theme.text};
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: "Gilroy", sans-serif;
    font-weight: 700;
    color: ${(props) => props.theme.title};
  }

  ul {
    list-style: none;
  }

  button {
    font: 700 14px "Gilroy", sans-serif;
    border: none;
    cursor: pointer;
    background-color: Transparent;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  /* display: flex; */
  flex: 1;
  margin-left: auto;
  margin-right: auto;
  max-width: ${(props) => props.theme.maxSize.lg};
  width: 100%;
`;

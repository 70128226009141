import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - 100px);
`;

export const HeaderContent = styled.div`
  width: 100%;
  padding-top: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;

  @media screen and (max-width: 500px) {
    justify-content: flex-end;
    min-height: 230px;
  }
`;

export const PageTitle = styled.h1`
  font-size: 64px;
  font-weight: 700;
  letter-spacing: -1.28px;
  text-align: center;

  @media screen and (max-width: 500px) {
    font-size: ${(props) => props.theme.space.md};
  }
`;

export const Body = styled.main`
  display: flex;
  width: 100%;
  padding: 65px 0 85px;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Picture = styled(LazyLoadImage).attrs({
  effect: 'blur',
})`
  height: 281px;
  width: 281px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 60px;

  @media screen and (max-width: 900px) {
    margin-right: 0px;
  }

  @media screen and (max-width: 350px) {
    height: 80vw;
    width: 80vw;
  }
`;

export const SabiaPicture = styled(LazyLoadImage).attrs({
  effect: 'blur',
})`
  height: 182px;
  width: 128px;
  object-fit: cover;
  margin-right: 24px;

  @media screen and (max-width: 900px) {
    margin-right: 0px;
    margin-bottom: 24px;
  }

  @media screen and (max-width: 350px) {
    height: 80vw;
    width: 80vw;
  }
`;

export const Article = styled.article``;

export const Title = styled.h4`
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.72px;
  text-transform: uppercase;
  color: ${(props) => props.theme.text};
  margin-top: 48px;
`;

export const Paragraph = styled.p`
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;

  margin-top: 26px;
`;

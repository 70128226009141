import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ReactComponent as Logo } from '~/assets/images/logo.svg';

import Menu from '~/components/Menu';
import ScrollToTop from '~/components/ScrollToTop';

import * as S from './styles';

function Header() {
  const history = useHistory();
  const location = useLocation();

  return (
    <S.Container path={location.pathname}>
      {location.pathname !== '/' && (
        <S.Content path={location.pathname}>
          <S.GoBack path={location.pathname} onClick={() => history.goBack()}>
            <S.GoBackIcon />
            <S.GoBackText>Back</S.GoBackText>
          </S.GoBack>

          <S.Logo path={location.pathname}>
            <Logo />
          </S.Logo>
          <S.LogoHome path={location.pathname}>
            <Logo />
          </S.LogoHome>

          <S.SwipeIcon path={location.pathname}>
            <S.SlideIcon />
            SLIDE
          </S.SwipeIcon>

          <Menu />
          <ScrollToTop />
        </S.Content>
      )}
    </S.Container>
  );
}

export default Header;

/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import * as S from './styles';
import video from '~/assets/videos/Full_Vinheta_Sabia.mp4';

const Intro = () => {
  const history = useHistory();
  const [activeVideo, setActiveVideo] = useState(false);
  const videoRef = useRef();

  const handleVideoEnded = useCallback(() => {
    setActiveVideo(false);
    history.push('/home');
  }, [history]);

  const handlePlay = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.play();
      setActiveVideo(true);
    }
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('loadeddata', handlePlay(), false);
    }
  }, [handlePlay]);

  return (
    <S.Main>
      {!activeVideo && <S.Loading />}
      {/* <S.Button ref={buttonRef} onClick={handlePlay}>
        Enter
      </S.Button> */}
      <S.Video>
        <video ref={videoRef} muted playsInline onEnded={handleVideoEnded}>
          <source src={video} type="video/mp4" />
        </video>
      </S.Video>
    </S.Main>
  );
};

export default Intro;

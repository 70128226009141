import * as Variables from './variables';

const light = {
  ...Variables,
  name: 'light',
  bg: Variables.color.whiteSolid,
  bgHeader: Variables.color.light2,
  bgFooter: Variables.color.light,
  title: Variables.color.blackSolid,
  text: Variables.color.dark,
  notActive: Variables.color.gray,
  danger: Variables.color.redSolid,
  // inputBorder: `1px solid ${Variables.color.gray2}`,
};

const Themes = {
  light,
};

export default Themes;

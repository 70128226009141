import React, { useState } from 'react';

import * as S from './styles';

function ScrollToTop() {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 300) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 300) {
      setShowScroll(false);
    }
  };

  window.addEventListener('scroll', checkScrollTop);

  const scroll = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <S.Container showScroll={showScroll} onClick={scroll}>
      <S.IconArrow />
    </S.Container>
  );
}

export default ScrollToTop;
